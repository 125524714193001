import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/OpenHAB_3_Camera_Widget/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB 3 Camera Widget",
  "path": "/Advanced_User/OpenHAB_3_Camera_Widget/",
  "dateChanged": "2021-01-12",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_OpenHAB.png",
  "social": "/images/Search/AU_SearchThumb_OpenHAB.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='OpenHAB 3 Camera Widget' dateChanged='2021-01-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_OpenHAB.png' twitter='/images/Search/AU_SearchThumb_OpenHAB.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/OpenHAB_3_Camera_Widget/' locationFR='/fr/Advanced_User/OpenHAB_3_Camera_Widget/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
 
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "openhab-3-camera-widget",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-3-camera-widget",
        "aria-label": "openhab 3 camera widget permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB 3 Camera Widget`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-your-camera"
        }}>{`Create your Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#create-the-widget-template"
        }}>{`Create the Widget Template`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "create-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#create-your-camera",
        "aria-label": "create your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create your Camera`}</h2>
    <p>{` First we need to add our camera as an `}<strong parentName="p">{`Equipment`}</strong>{`. To do this go to `}<strong parentName="p">{`Settings`}</strong>{` and click on `}<strong parentName="p">{`Model`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/42de8/OpenHAB3_Camera_Widget_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABdElEQVQoz4XRu0/cQBDAYXdEeWBf1mevvbPvWfv8ts++AIcuF3FKB4KGBpQuFRJlmhSIPz2CU6Jcmnya9jcaaTxEzPMc0TV1DRzGuhy6zhij/weN9hZFMY7TcpzO1ufA+bBcXux2SsqY0uQv6avkkGdFmoTB3H83999S4h9/eP/m6Mj3/dkfQTALguNXswOB9+n2sb95ENs7efGNnVxFDKq6YRxmhHwMw5chJIyiLMucc3EcEULC37z27qm+f6aXP9Kbn3T3vRJ4td196YcJs8HYVqpOqkrI07P1ZrOx1rIUADjngjHmLQyUKBDmmYhlQiaw1+vPX5t2mxebLD9R+txgD6Ko6rIo9xnnQgjJGHhV09ZNZ6xDlyulKdhhOK3zRY7OKm2EMkJyLqbVSmvNGBzEZVlWVWWMQUQpZcJgGEeldUyTlDHGeURjmiRFUWqlldL/xm3bIqJFVFLSFLrllDm3fzVjkL7sgHFa9f3gXLa/fB//Amd4SSLVCmc0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6fd7d077d6a38ba1462c69bcc441f36/e4706/OpenHAB3_Camera_Widget_01.avif 230w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/d1af7/OpenHAB3_Camera_Widget_01.avif 460w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/7f308/OpenHAB3_Camera_Widget_01.avif 920w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/e8a68/OpenHAB3_Camera_Widget_01.avif 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a6fd7d077d6a38ba1462c69bcc441f36/a0b58/OpenHAB3_Camera_Widget_01.webp 230w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/bc10c/OpenHAB3_Camera_Widget_01.webp 460w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/966d8/OpenHAB3_Camera_Widget_01.webp 920w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/de74f/OpenHAB3_Camera_Widget_01.webp 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a6fd7d077d6a38ba1462c69bcc441f36/81c8e/OpenHAB3_Camera_Widget_01.png 230w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/08a84/OpenHAB3_Camera_Widget_01.png 460w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/c0255/OpenHAB3_Camera_Widget_01.png 920w", "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/42de8/OpenHAB3_Camera_Widget_01.png 1033w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a6fd7d077d6a38ba1462c69bcc441f36/c0255/OpenHAB3_Camera_Widget_01.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on `}<strong parentName="p">{`Create Equipment from Thing`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/75493bcd0d8bc51a441694db4f24d2db/f2d92/OpenHAB3_Camera_Widget_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABPklEQVQY003N70rDMBSH4d6AOJY2WfOvOTlJ2m52zTqbziJD8Ar8Jih4J96BCl6xbBPx4Xw7vPwyYwwiGmPqupZKbZzdBGcR3RkibrfbeZ6HYXD/eO+dc1nTNNNJOh6PAAAWm7YN5x+eWWv12WXGe4+IAGCMyQAspWy5JIQQSpmUahzTOCZrsSw550IISSldErLM8xXnSuuCsavr68Vikc2H6ZBScOgdooWqMnE39DEiohBSSqWULii1ldn4wIuCknxFCM9zSvJsPz9Mx0doe2gjNJ2uqjFNKU3W/sZCac3YyGUyfmrHw839FHZ34PdcZOr10759Fc8f9OV79fSuwe3iLvbxUp5O6ZKytZC9cV2Ine871/XGhpJnm23s4gCutr4FF07Lt6mpm79YKU0pZSUXUgoTxHriFQipWFn+AOqHPIql6enpAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75493bcd0d8bc51a441694db4f24d2db/e4706/OpenHAB3_Camera_Widget_02.avif 230w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/d1af7/OpenHAB3_Camera_Widget_02.avif 460w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/7f308/OpenHAB3_Camera_Widget_02.avif 920w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/fa017/OpenHAB3_Camera_Widget_02.avif 1027w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/75493bcd0d8bc51a441694db4f24d2db/a0b58/OpenHAB3_Camera_Widget_02.webp 230w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/bc10c/OpenHAB3_Camera_Widget_02.webp 460w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/966d8/OpenHAB3_Camera_Widget_02.webp 920w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/25665/OpenHAB3_Camera_Widget_02.webp 1027w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/75493bcd0d8bc51a441694db4f24d2db/81c8e/OpenHAB3_Camera_Widget_02.png 230w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/08a84/OpenHAB3_Camera_Widget_02.png 460w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/c0255/OpenHAB3_Camera_Widget_02.png 920w", "/en/static/75493bcd0d8bc51a441694db4f24d2db/f2d92/OpenHAB3_Camera_Widget_02.png 1027w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/75493bcd0d8bc51a441694db4f24d2db/c0255/OpenHAB3_Camera_Widget_02.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And select your camera that you added using the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_3_Camera_Binding/"
      }}>{`Camera Binding`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/35a31/OpenHAB3_Camera_Widget_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.17391304347826%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABT0lEQVQoz4WR207DMAyGewdLaMvqxEmWc7u161YOGhNj4yEQXDDe/0mgO2mABJ+sXPzWF8tywjmPMZZl2TQNIhJKkfMsTekRcuSUXO2glCZFAVmW7Vp9lOe5UgoAiiMMWFEUUPQJAAyHwyzv6eUYQlVGiSg45wzy/FoIAQCMMd4n/auU4kcYY2z3d5qmiY3jcTPjyjJpuDKEUKX1/OZm2rZ1c2CxWEyn06bpg7puulnrjRkMBkm+2vLNNn18T1cf+fLtMr2uJHaTSelDcH6PtdafEUJAFISQRI20sY6j4CgQxQUhDyhe2/lL3bbWjaxz1tnv5Zw/yFIKrUenlQghiPi0etqsnyeT2hhr/5Sl1hoRTzJHvL2777pb78Nv85ustXbOa63PJovlcrVeb+az7p/JxpgYozFmfxtKKQBUVTUeV94HIaSU6kcpNQJgX/In7lE8n+4jSj0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/e4706/OpenHAB3_Camera_Widget_03.avif 230w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/d1af7/OpenHAB3_Camera_Widget_03.avif 460w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/7f308/OpenHAB3_Camera_Widget_03.avif 920w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/25925/OpenHAB3_Camera_Widget_03.avif 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/a0b58/OpenHAB3_Camera_Widget_03.webp 230w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/bc10c/OpenHAB3_Camera_Widget_03.webp 460w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/966d8/OpenHAB3_Camera_Widget_03.webp 920w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/5ea8e/OpenHAB3_Camera_Widget_03.webp 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/81c8e/OpenHAB3_Camera_Widget_03.png 230w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/08a84/OpenHAB3_Camera_Widget_03.png 460w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/c0255/OpenHAB3_Camera_Widget_03.png 920w", "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/35a31/OpenHAB3_Camera_Widget_03.png 1028w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f0b6041bfdfe7c307a980680cc1ae6d1/c0255/OpenHAB3_Camera_Widget_03.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{` Make sure to click `}<strong parentName="p">{`show advanced`}</strong>{` to see all the channels and tick the `}<strong parentName="p">{`Pan`}</strong>{`, `}<strong parentName="p">{`Tilt`}</strong>{`, `}<strong parentName="p">{`Zoom`}</strong>{`, `}<strong parentName="p">{`GoToPreset`}</strong>{` and `}<strong parentName="p">{`MJPEG URL`}</strong>{` channels as a minimum.`}</p>
    <h2 {...{
      "id": "create-the-widget-template",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#create-the-widget-template",
        "aria-label": "create the widget template permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create the Widget Template`}</h2>
    <p>{`Currently (12.01.2021) the camera widget is not yet part of the standard OpenHAB widgets and has to be created manually. We need to add our widget code via the `}<strong parentName="p">{`Developer Tools`}</strong>{` menu. Click on `}<strong parentName="p">{`Create Widget`}</strong>{` and copy&paste the following code:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/b59fb/OpenHAB3_Camera_Widget_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.304347826086953%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABFElEQVQY023Pu07DMACF4TxBStKGNBc7sZ2L73FqkiChloqhXZpISFUZOsDAUvH+O6IIMcCns/7DcYqisNb2fc8YKzG2ulFKcM6qqqrrGmO82+2NMUmSAgABgMtldBuGURjGYehkiACYJSCLE1BTem9W665rG6WV1EoSQqZpKssqiuI0BWkKFouF5914V85DZ1aiQnFQwojSWgu17u4aLSmljDFCyOFwIIQEQbC8ms/n/g9HTZf2+QM8vcLtmUndSrUdhsE2QorveBxHCOFs5vq+d90vh+7f9PgOHs9w80KFaqXcDL01X785Zxjj4/GY57nruv4fDi8yUeYliEoYcUYbodZ911sjpRCCI4ROpxNC6N/4E6ZaNMm9xVLMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/e4706/OpenHAB3_Camera_Widget_04.avif 230w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/d1af7/OpenHAB3_Camera_Widget_04.avif 460w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/7f308/OpenHAB3_Camera_Widget_04.avif 920w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/2e806/OpenHAB3_Camera_Widget_04.avif 1285w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/a0b58/OpenHAB3_Camera_Widget_04.webp 230w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/bc10c/OpenHAB3_Camera_Widget_04.webp 460w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/966d8/OpenHAB3_Camera_Widget_04.webp 920w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/918a7/OpenHAB3_Camera_Widget_04.webp 1285w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/81c8e/OpenHAB3_Camera_Widget_04.png 230w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/08a84/OpenHAB3_Camera_Widget_04.png 460w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/c0255/OpenHAB3_Camera_Widget_04.png 920w", "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/b59fb/OpenHAB3_Camera_Widget_04.png 1285w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bd3ebf20d7a9cdec88eb0ddf9b8f8781/c0255/OpenHAB3_Camera_Widget_04.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`uid`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ClickablePTZCamera
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`props`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`parameters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` item
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Select the Camera to Control
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` camera
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEXT
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`context`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` item
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Item for Switch
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` switchItem
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`false`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` TEXT
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Invert Tilt Controls
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` invertTilt
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` BOOLEAN
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`label`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Show Discrete Controls
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` showControls
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` BOOLEAN
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`parameterGroups`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`timestamp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Dec 29`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2020`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 11`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`56`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`36 AM
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` f7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`card
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` no`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`margin
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`expandable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 6px
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`--f7-card-margin-horizontal`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0px
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`width`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 16rem
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`height`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 9rem
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`slots`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`default`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` f7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`content
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`opened`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`fade`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`out
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`background-image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ="url('"+items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`props.camera + "_MJPEGURL"`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`.state+"')"
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`background-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 17rem 10rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`background-repeat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` no`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`repeat
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`border-radius`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 6px
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`z-index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`-1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` f7`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`content
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`opened`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`fade`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`in
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`background-image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ="url('"+items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`props.camera + "_MJPEGURL"`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`.state+"')"
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`background-size`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 100% auto
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`background-position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` center
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`background-repeat`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` no`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`repeat
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`z-index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`-1`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0em 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(items[props.switchItem].state === 'ON') ? 'cyan' : 'white'"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(items[props.switchItem].state === 'ON') ? '0.4' : '0.3'"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.switchItem `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!==`}</span>{` undefined
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` power
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` toggle
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.switchItem
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ON
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommandAlt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` OFF
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showControls === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` arrow_left
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` command
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera + "_Pan"
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` INCREASE
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showControls === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` arrow_up
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` command
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera + "_Tilt"
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(props.invertTilt) ? 'INCREASE' : 'DECREASE'"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showControls === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` arrow_down
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` command
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera + "_Tilt"
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(props.invertTilt) ? 'DECREASE' : 'INCREASE'"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showControls === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` arrow_right
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` command
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera + "_Pan"
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` DECREASE
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showControls === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` plus
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` command
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera + "_Zoom"
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` INCREASE
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`visible`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.showControls === true
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` minus
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` command
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera + "_Zoom"
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` DECREASE
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` list_number
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` options
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera + "_GoToPreset"
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`class`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prevent`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`open
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`margin`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0 0.2em
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0.3`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` gear_alt
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`22`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` group
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actionGroupPopupItem`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` =props.camera
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 7.8rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`right`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0.2rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(items[props.camera + '_MotionAlarm'].state === 'ON') ? '0.5' : '0'"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` eye
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`component`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` oh`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`link
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`style`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`position`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` absolute
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`top`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 7.7rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`left`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 0rem
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` white
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`opacity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"=(items[props.camera + '_AudioAlarm'].state === 'ON') ? '0.5' : '0'"`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconF7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ear
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`iconSize`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`18`}</span></code></pre></div>
    <p>{`Now, when you edit your page, you will have the option to add a `}<strong parentName="p">{`Personal Widget`}</strong>{` with name `}<strong parentName="p">{`ClickablePTZCamera`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/271871849d3ee726f49c1c18bcfb7169/09e48/OpenHAB3_Camera_Widget_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3ElEQVQY002NbUvEMBCE+9HLblKv2dTrdpNNilekVVvFE18+eP//V8ldERyGYRgGnoqIDncHakNMSVU1RR1nnV71OGtKeh1/zue2beu6ttYhorV2y8o3nkXuRT4kLjG9SHzL5WscT8OwSlxjeo/6PT/W+71zzloLAM45AEDEylp72zTatp9JnzQvqutxWh+WdZwWzc+aT5oj4I0xCLAxN13IxpjdbgcA2nWFuXBXUil5vCRvC9fOGWPgnxAREKtARORDCNwz9/3mEkX+Ovd9CNcXkffeEw3DICLM/AtEUhz9MZIq8QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/271871849d3ee726f49c1c18bcfb7169/e4706/OpenHAB3_Camera_Widget_05.avif 230w", "/en/static/271871849d3ee726f49c1c18bcfb7169/d1af7/OpenHAB3_Camera_Widget_05.avif 460w", "/en/static/271871849d3ee726f49c1c18bcfb7169/7f308/OpenHAB3_Camera_Widget_05.avif 920w", "/en/static/271871849d3ee726f49c1c18bcfb7169/5578a/OpenHAB3_Camera_Widget_05.avif 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/271871849d3ee726f49c1c18bcfb7169/a0b58/OpenHAB3_Camera_Widget_05.webp 230w", "/en/static/271871849d3ee726f49c1c18bcfb7169/bc10c/OpenHAB3_Camera_Widget_05.webp 460w", "/en/static/271871849d3ee726f49c1c18bcfb7169/966d8/OpenHAB3_Camera_Widget_05.webp 920w", "/en/static/271871849d3ee726f49c1c18bcfb7169/7efa9/OpenHAB3_Camera_Widget_05.webp 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/271871849d3ee726f49c1c18bcfb7169/81c8e/OpenHAB3_Camera_Widget_05.png 230w", "/en/static/271871849d3ee726f49c1c18bcfb7169/08a84/OpenHAB3_Camera_Widget_05.png 460w", "/en/static/271871849d3ee726f49c1c18bcfb7169/c0255/OpenHAB3_Camera_Widget_05.png 920w", "/en/static/271871849d3ee726f49c1c18bcfb7169/09e48/OpenHAB3_Camera_Widget_05.png 974w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/271871849d3ee726f49c1c18bcfb7169/c0255/OpenHAB3_Camera_Widget_05.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In `}<strong parentName="p">{`Select the Camera to Control`}</strong>{` select the camera you created in step 1:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4451829600f069ca74e0cff6f27a4add/ec3e2/OpenHAB3_Camera_Widget_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.260869565217384%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRklEQVQY023N3WobMRCG4T127NX/SBrNSrOVbC/G6zh2aUxoINCbKIXm/i8j9R41kIf39OPr9vt9jGitBQCllDYmIvoQ4J5HSsfT/Ph0Pp5OQ8kQgo8xxJgxSq07ouQBrDHgnNLKKnUK8Vz4iccz87nwnOi4dIh4TDTTMA/DYyLUukPKEND5CAF7pc1m86vwW2uvhV95/Jn5O4Srg6vzV/DPmG6JbjS8YCKpOnz7K25/1j9+b17exfZZbB4IU611t9tFRHCAmJg558w8LnEpnIh6ITocCgS0y7PSdr1e11oPh0NtLUZMmPCTFEIcx2+Xy8Vo0w1EGKO1BsBJKVerVa21tVZKQUQA8J8BABFN0ySE6HIuuRRYKKWcNdtta60xc0pp2Yb/A/BEwzRNfX8fZyJyd2C0wjI7SEpJIaRUX5NS9n3/7/kDKsIzoXbDNgUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4451829600f069ca74e0cff6f27a4add/e4706/OpenHAB3_Camera_Widget_06.avif 230w", "/en/static/4451829600f069ca74e0cff6f27a4add/d1af7/OpenHAB3_Camera_Widget_06.avif 460w", "/en/static/4451829600f069ca74e0cff6f27a4add/7f308/OpenHAB3_Camera_Widget_06.avif 920w", "/en/static/4451829600f069ca74e0cff6f27a4add/cc3cf/OpenHAB3_Camera_Widget_06.avif 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4451829600f069ca74e0cff6f27a4add/a0b58/OpenHAB3_Camera_Widget_06.webp 230w", "/en/static/4451829600f069ca74e0cff6f27a4add/bc10c/OpenHAB3_Camera_Widget_06.webp 460w", "/en/static/4451829600f069ca74e0cff6f27a4add/966d8/OpenHAB3_Camera_Widget_06.webp 920w", "/en/static/4451829600f069ca74e0cff6f27a4add/c5bb7/OpenHAB3_Camera_Widget_06.webp 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4451829600f069ca74e0cff6f27a4add/81c8e/OpenHAB3_Camera_Widget_06.png 230w", "/en/static/4451829600f069ca74e0cff6f27a4add/08a84/OpenHAB3_Camera_Widget_06.png 460w", "/en/static/4451829600f069ca74e0cff6f27a4add/c0255/OpenHAB3_Camera_Widget_06.png 920w", "/en/static/4451829600f069ca74e0cff6f27a4add/ec3e2/OpenHAB3_Camera_Widget_06.png 997w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4451829600f069ca74e0cff6f27a4add/c0255/OpenHAB3_Camera_Widget_06.png",
              "alt": "Trying out OpenHAB 3 in Docker",
              "title": "Trying out OpenHAB 3 in Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The widget allows you to pan&tilt your camera as well as giving you access to your camera's live video and all function that you selected when creating your camera in step 1:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/5ba6e36dde6eb32de0e8f8eb1ae50594/OpenHAB3_Camera_Widget_07.gif",
        "alt": "Trying out OpenHAB 3 in Docker"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      